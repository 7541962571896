import AdjustIcon from "@mui/icons-material/Adjust";
import SubjectIcon from "@mui/icons-material/Subject";
import { MenuItem, Select, Stack, TextField } from "@mui/material";
import { useSetAtom } from "jotai";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { editQuestionAtom, createNewQuestionAtom } from "components/dover/FeedbackTemplates/atoms";
import SelectOptionsList from "components/dover/FeedbackTemplates/SelectOptionsList";
import { FeedbackTemplateQuestion } from "components/dover/FeedbackTemplates/types";
import { Button, ButtonVariant } from "components/library/Button";
import { Card } from "components/library/Card";
import { BodySmall } from "components/library/typography";
import { RequiredAsterisk } from "components/RequiredAsterisk";
import { FeedbackTemplateQuestionComponent, SelectFieldOption } from "services/openapi";
import { colors } from "styles/theme";

const generateNewQuestionName = (): string => {
  return uuidv4();
};

export const feedbackTemplateQuestionTypeIconAndLabel = {
  [FeedbackTemplateQuestionComponent.TEXT_FIELD]: {
    icon: <SubjectIcon htmlColor={colors.grayscale.gray400} />,
    label: "Free text",
  },
  [FeedbackTemplateQuestionComponent.SELECT]: {
    icon: <AdjustIcon htmlColor={colors.grayscale.gray400} />,
    label: "Select",
  },
};

const QuestionTypeLabel = ({ value }: { value: FeedbackTemplateQuestionComponent }): React.ReactElement => {
  const questionTypeIconAndLabel = feedbackTemplateQuestionTypeIconAndLabel[value];
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {questionTypeIconAndLabel.icon}
      <BodySmall color={colors.grayscale.gray700}>{questionTypeIconAndLabel.label}</BodySmall>
    </Stack>
  );
};

export const QuestionEditor = ({
  close,
  existingQuestion,
}: {
  close: Function;
  existingQuestion?: FeedbackTemplateQuestion;
}): React.ReactElement => {
  const [question, setQuestion] = useState(existingQuestion?.label || "");
  const [description, setDescription] = useState(existingQuestion?.helperText || "");
  const [questionType, setQuestionType] = useState<FeedbackTemplateQuestionComponent>(
    existingQuestion?.component || FeedbackTemplateQuestionComponent.TEXT_FIELD
  );
  const [selectOptions, setSelectOptions] = useState<SelectFieldOption[]>(
    existingQuestion?.component === FeedbackTemplateQuestionComponent.SELECT
      ? existingQuestion.options
      : [{ label: "", value: "" }] // initialize with one empty option
  );

  // Jotai Question Setters
  const editQuestion = useSetAtom(editQuestionAtom);
  const createNewQuestion = useSetAtom(createNewQuestionAtom);

  // Derived state
  const disableButton =
    !question || (questionType === FeedbackTemplateQuestionComponent.SELECT && !selectOptions.length);

  const handleSave = (): void => {
    if (existingQuestion) {
      const updatedQuestion = {
        ...existingQuestion,
        label: question,
        helperText: description,
        options: selectOptions,
        component: questionType,
      };
      editQuestion(updatedQuestion);
    } else {
      const newQuestion = {
        label: question,
        name: generateNewQuestionName(),
        helperText: description,
        component: questionType,
        options: selectOptions,
      };
      createNewQuestion(newQuestion);
    }

    close();
  };

  return (
    <Card>
      <Stack spacing={2}>
        <Stack spacing={1}>
          <BodySmall weight="600">
            Type your question
            <RequiredAsterisk />
          </BodySmall>
          <Stack direction="row" justifyContent="space-between" spacing={1}>
            <Stack flexGrow={3}>
              <TextField
                size="small"
                multiline
                fullWidth
                required
                value={question}
                onChange={(event): void => {
                  setQuestion(event.target.value);
                }}
              />
            </Stack>
            <Stack flexGrow={1}>
              <Select
                size="small"
                value={questionType}
                onChange={(e): void => setQuestionType(e.target.value as FeedbackTemplateQuestionComponent)}
                renderValue={(value): React.ReactNode => <QuestionTypeLabel value={value} />}
              >
                {Object.values(FeedbackTemplateQuestionComponent).map(value => (
                  <MenuItem key={value} value={value}>
                    <QuestionTypeLabel value={value} />
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Stack>
        </Stack>
        <Stack spacing={1}>
          <BodySmall weight="600">Helper text (optional)</BodySmall>
          <TextField
            size="small"
            multiline
            fullWidth
            placeholder={"Add in any additional info here. There is a 1000 character limit."}
            value={description}
            onChange={(event): void => {
              setDescription(event.target.value);
            }}
            inputProps={{ maxLength: 1000 }}
          />
        </Stack>
        {questionType === FeedbackTemplateQuestionComponent.SELECT && (
          <SelectOptionsList options={selectOptions} setOptions={setSelectOptions} />
        )}
        <Stack justifyContent="flex-end" direction="row" spacing={1}>
          <Button onClick={(): void => close()} variant={ButtonVariant.Secondary}>
            Cancel
          </Button>
          <Button variant={ButtonVariant.Primary} onClick={handleSave} disabled={disableButton}>
            {existingQuestion ? "Save" : "Add question"}
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
};
